import { configureStore } from '@reduxjs/toolkit'
import gameRoomReducer from './slices' // Adjust the path as necessary

// Create the Redux store
const store = configureStore({
  reducer: {
    gameRoom: gameRoomReducer,
    // Add more reducers if needed
  },
})

export default store
