import { getItemFromLocalStorage } from '../utils/getItemFromLocalStorage'

const getApiUrl = () => {
  const stageUrl = getItemFromLocalStorage('stageUrl')

  if (
    window.location.hostname === 'www.panenkafc.gg' ||
    window.location.hostname === 'panenkafc.gg' ||
    getItemFromLocalStorage('isProd')
  ) {
    return import.meta.env.VITE_ProdApiUrl
  } else if (stageUrl) {
    return stageUrl
  } else {
    return import.meta.env.VITE_DevApiUrl
  }
}

export default getApiUrl
