export const intlDateFormatter = ({ date, formatStandard, formatOptions }) => {
  const datetoBeFormatted = new Date(date)

  const formattedDate = new Intl.DateTimeFormat(
    formatStandard,
    formatOptions
  ).format(datetoBeFormatted)

  return formattedDate
}
