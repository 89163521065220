import smallFootball from '../assets/fb2.png'

function Loading() {
  return (
    <>
      <div className='LoadingWindow'>
        {/* <h1>Loading...</h1> */}
        <div className='loader'>
          <div className='dot'>
            <img src={smallFootball} alt='' width={'50px'} height={'50px'} />
          </div>
          <div className='dot'>
            <img src={smallFootball} alt='' width={'60px'} height={'60px'} />
          </div>
          <div className='dot'>
            <img src={smallFootball} alt='' width={'50px'} height={'50px'} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Loading
