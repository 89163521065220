import { useEffect, useState } from 'react'
import { NOTIF_TAB, pageSizes } from '../../constants'

const NOTIF_RECEIVED = 'notifications.notification_received'

export const useNotifications = ({
  novuRef,
  tabStatus,
  offset,
  setHasMore,
}) => {
  const [notifications, setNotifications] = useState([])
  const [isFetchingNotifications, setIsFetchingNotifications] = useState(null)

  useEffect(() => {
    const fetchNotifications = async () => {
      if (novuRef?.current) {
        setIsFetchingNotifications(true)
        try {
          const headlessService = novuRef.current
          const response = await headlessService.notifications.list({
            limit: pageSizes.medium,
            offset,
            ...(tabStatus === NOTIF_TAB.UNREAD_ONLY && { read: false }), // set filter to fetch only unread notifications
          })
          setNotifications((prev) => [...prev, ...response.data.notifications])
          setHasMore(response.data.hasMore)
        } catch (error) {
          console.error('Failed to fetch notifications:', error)
        } finally {
          setIsFetchingNotifications(false)
        }
      }
    }

    fetchNotifications()

    // setup real time listener for notifications
    const setupNotificationListener = () => {
      if (!novuRef?.current) return

      const handleNewNotification = (data) => {
        console.log(data.result, 'new notification received')
        setNotifications((prev) => [data.result, ...prev])
      }

      const { current: headlessService } = novuRef
      headlessService.on(NOTIF_RECEIVED, handleNewNotification)

      return () => headlessService.off(NOTIF_RECEIVED, handleNewNotification)
    }
    const cleanup = setupNotificationListener()
    return cleanup
  }, [novuRef, tabStatus, offset, setHasMore])

  return [{ notifications, isFetchingNotifications }, setNotifications]
}
