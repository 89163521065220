import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Routings } from './Config'
import Loading from './Component/Loading'
import ProtectedRoute from './Component/ProtectedRoute'
import NotFoundPage from './Pages/NotFoundPage'

const AppRoutes = () => {
  return (
    <Routes>
      {Routings.map((route) => {
        const DynamicComponent = route.importFrom

        return (
          <Route
            key={route.id}
            path={route.path}
            element={
              <Suspense fallback={<Loading />}>
                {route.isProtected ? (
                  <ProtectedRoute role={route.userType}>
                    <DynamicComponent />
                  </ProtectedRoute>
                ) : (
                  <DynamicComponent />
                )}
              </Suspense>
            }
          />
        )
      })}
      <Route path='/*' element={<NotFoundPage />} />
    </Routes>
  )
}

export default AppRoutes
