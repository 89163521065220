import { useEffect, useRef } from 'react'
// import { HeadlessService } from '@novu/headless'
import { Novu } from '@novu/js'
import { useUser } from '../../UserContext'
import { isProd } from '../../utils/isProd'

const NOVU_APP_ID = isProd ? 'IkAOKfXksQ3o' : 'zDuJFCFgO7GF'

// Initializes Novu session
export const useNovuInit = () => {
  const { user } = useUser()
  const headlessServiceRef = useRef(null)

  useEffect(() => {
    if (user.id) {
      const headlessService = new Novu({
        applicationIdentifier: NOVU_APP_ID,
        subscriberId: user.id,
        useCache: false,
      })
      headlessServiceRef.current = headlessService
    }
  }, [user.id])

  return headlessServiceRef
}
