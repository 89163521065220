import { createContext, useContext, useState } from 'react'
import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import { setTokenOnAxios, useApiGet } from './api/apiHooks'
import { getItemFromLocalStorage } from './utils/getItemFromLocalStorage'
import { getUserDetailsUrl } from './api/urls'
import Loading from './Component/Loading'
import useCleanupLocalStorage from './hooks/useCleanupLocalStorage'
import { OLD_KEYS } from './constants'
import { mixpanelUserProperties } from './Component/Tracker/MixpanelConfig'

const UserContext = createContext()

export const useUser = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser must be used within a UserContext')
  }
  return context
}

const UserProvider = ({ children }) => {
  const [token, setToken] = useState(getItemFromLocalStorage('accessToken'))
  setTokenOnAxios(token)

  const { data, isLoading, error } = useApiGet({
    queryKey: [getUserDetailsUrl()],
    enabled: !!token, // only attempt to fetch user is there is a token
    retry: false,
  })

  // this is temporal code to remove properties we formally stored in localstorage still saved in users browsers
  useCleanupLocalStorage(OLD_KEYS)

  const user = data
    ? {
        displayName: data.displayName,
        picture: data.picture,
        email: data.email,
        firstName: data.firstName,
        hasVerifiedEmail: data.hasVerifiedEmail,
        id: data.id,
        userType: data.role,
        lastName: data.lastName,
        token,
        wallets: data.wallets || [],
      }
    : {}

  if (user) {
    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.displayName,
    })
    mixpanelUserProperties(user.email, user)
  }

  const setAuthToken = (authToken) => {
    if (authToken) {
      setToken(authToken)
      setTokenOnAxios(authToken)
      localStorage.setItem('accessToken', authToken)
    }
  }

  const removeAuthToken = () => {
    setToken(null) // remove from App state
    setTokenOnAxios(null) // proceed to remove from axios
    localStorage.removeItem('accessToken') // remove from localStorage
    Sentry.setUser(null)
  }

  return (
    <UserContext.Provider
      value={{ user, isLoading, error, setAuthToken, removeAuthToken }}
    >
      {isLoading ? <Loading /> : children}
    </UserContext.Provider>
  )
}

export default UserProvider

UserProvider.propTypes = {
  children: PropTypes.element,
}
