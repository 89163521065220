import { Link } from 'react-router-dom'
import Football from '../assets/football.png'
import netImg from '../assets/goal net.png'
import Navbar from '../Component/Navbar'

function NotFoundPage() {
  return (
    <main>
      <Navbar />
      <section className='notFoundSection'>
        <img src={netImg} alt='' className='netImg' />
        <div className='content'>
          <i className='bi bi-emoji-frown'></i>
          <h1>
            Page <span className='blueGradientTxt'>not found</span>{' '}
          </h1>
          <p className='greenGradientTxt'>
            Sorry! the current url is not exist.
          </p>
          <Link to={'/'} className='btn'>
            Go to Home
          </Link>
          <img src={Football} alt='' className='footballImg' />
        </div>
      </section>
    </main>
  )
}

export default NotFoundPage
