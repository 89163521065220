import { useState, useEffect, useRef } from 'react'

function useDropdown() {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        closeDropdown() // Close dropdown if clicked outside
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  return { isOpen, toggleDropdown, closeDropdown, ref }
}

export default useDropdown
