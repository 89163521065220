import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import './index.css'
import './Home.css'
import App from './App.jsx'
import { Provider } from 'react-redux'
import store from './Component/Redux/store.js'
import { isProd } from './utils/isProd.js'

Sentry.init({
  dsn: 'https://6536d779cc7edb71c20d7531cbf44db4@o4508059082948608.ingest.de.sentry.io/4508059084980304',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.withSentryReactRouterV6Routing,
  ],
  environment: isProd ? 'production' : 'development',
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/prod-api\.panenkafc\.gg\/api/,
    /^https:\/\/test-api\.panenkafc\.gg\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>
)
