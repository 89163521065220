import { createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { getItemFromLocalStorage } from './utils/getItemFromLocalStorage'
const WalletConnectorContext = createContext()

export const WalletConnectorProvider = ({ value, children }) => {
  const lastWalletLoaded = getItemFromLocalStorage('lastWalletLoaded')

  const setWalletLoaded = (key, value) => {
    localStorage.setItem(key, value)
  }
  return (
    <WalletConnectorContext.Provider
      value={{ ...value, lastWalletLoaded, setWalletLoaded }}
    >
      {children}
    </WalletConnectorContext.Provider>
  )
}

export const useWalletConnector = () => {
  const context = useContext(WalletConnectorContext)

  if (context === undefined) {
    throw new Error(
      'useWalletConnector must be used within a WalletConnectorContext'
    )
  }
  return context
}

WalletConnectorProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.element,
}
