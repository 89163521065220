import { useState } from 'react'
import ErrorPopup from '../../Component/ErrorPopup'
import PropTypes from 'prop-types'
import PulseLoading from '../../Component/PulseLoading'
import { useApiGet } from '../../api/apiHooks'
import { getAliasLeaguesUrl, getFPLTeamsUrl } from '../../api/urls'

function FormSection({ setPlayerQuery, isLoading }) {
  const [leagueIdentity, setLeagueIdentity] = useState(0)

  const {
    isLoading: isFetchingAliasLeagues,
    data: aliasLeagues,
    isError: isFetchingAliasError,
    error: fplAliasError,
  } = useApiGet({
    queryKey: [getAliasLeaguesUrl()],
  })

  const {
    isLoading: isFetchingFPLTeams,
    data: fplTeams,
    isError: isFetchingFPLError,
    error: fplTeamsError,
  } = useApiGet({
    queryKey: [getFPLTeamsUrl()],
  })

  // State to manage form data============================
  const [formData, setFormData] = useState({
    selectedLeague: '',
    selectedClub: '',
  })

  // Function to handle changes in form inputs==============
  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  //   handle form for fetch players
  const handleSubmit = (e) => {
    e.preventDefault()

    setPlayerQuery({
      league: leagueIdentity,
      club: formData.selectedClub,
    })
  }

  return (
    <form onSubmit={handleSubmit} className='Admin-Form'>
      {isFetchingAliasError && (
        <ErrorPopup
          isError={isFetchingAliasError}
          errorMsg={fplAliasError?.message}
        />
      )}

      {isFetchingFPLError && (
        <ErrorPopup
          isError={isFetchingFPLError}
          errorMsg={fplTeamsError?.message}
        />
      )}

      <div className='inputBox'>
        <label>Select Alias League</label>
        <select
          name='inputSelectedLeague'
          id='inputSelectedLeague'
          required
          value={formData.inputSelectedLeague || ''}
          onChange={(e) => {
            handleInputChange(e)
            const league = aliasLeagues.find((item) => {
              return e.target.value === item.id
            })
            setLeagueIdentity(league.identity)
          }}
        >
          {isFetchingAliasLeagues ? (
            <option value=''>Loading...</option>
          ) : (
            <>
              <option value=''>..Select..</option>
              {aliasLeagues?.length > 0 &&
                aliasLeagues.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
            </>
          )}
        </select>
      </div>

      {/* select club related to league  */}
      <div className='inputBox'>
        <label>Club</label>
        <select
          name='selectedClub'
          id='selectedClub'
          required
          value={formData.selectedClub || ''}
          onChange={handleInputChange}
        >
          {isFetchingFPLTeams ? (
            <option value=''>Loading...</option>
          ) : (
            <>
              <option value=''>..Select..</option>
              {fplTeams.map((item) => {
                return (
                  <option
                    key={item.id}
                    value={item.id}
                    disabled={item.league_id !== leagueIdentity}
                  >
                    {item.name}
                  </option>
                )
              })}
            </>
          )}
        </select>
      </div>

      <div className='inputBox' style={{ width: '100%' }}>
        <button
          type='submit'
          className='btn'
          style={{ width: '50%', marginInline: 'auto' }}
        >
          Fetch players {isLoading && <PulseLoading />}
        </button>
      </div>
    </form>
  )
}

FormSection.propTypes = {
  setPlayerQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default FormSection
