import { useQuery, useMutation } from '@tanstack/react-query'
import getApiUrl from '../Component/getApiUrl'
import axios from 'axios'

export const axiosInstance = axios.create({
  baseURL: getApiUrl() + '/api/v1/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const setTokenOnAxios = (token) => {
  axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`
}

export const getRequest = async (request, hasPagination) => {
  const response = await axiosInstance.get(request.queryKey[0])
  return hasPagination ? response.data : response.data?.data
}

const postRequest = (request) => axiosInstance.post(request.url, request.data)

const deleteRequest = (request) =>
  axiosInstance.delete(request.url, { data: request.data })

const putRequest = async (request) => {
  const response = await axiosInstance.put(
    request.url,
    request.data,
    request.config
  )
  return response.data
}

export const useApiGet = ({
  queryKey,
  hasPagination = false,
  ...queryOptions
}) => {
  return useQuery({
    queryKey,
    queryFn: (req) => getRequest(req, hasPagination),
    ...queryOptions,
  })
}

export const useApiPost = (options) =>
  useMutation({ mutationFn: postRequest, ...options })

export const useApiDelete = (options) =>
  useMutation({ mutationFn: deleteRequest, ...options })

export const useApiPut = (options) =>
  useMutation({ mutationFn: putRequest, ...options })
