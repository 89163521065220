import { useRef, useEffect } from 'react'

function useInfiniteScroll({ fetchMore, hasMore, isFetching }) {
  const observerRef = useRef(null)

  useEffect(() => {
    if (isFetching || !hasMore) return // Exit if currently fetching or no more data to fetch

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        fetchMore() // Trigger the fetch callback
      }
    })

    // Observe the target element if ref is set
    if (observerRef.current) {
      observer.observe(observerRef.current)
    }

    // Cleanup when component unmounts or hasMore changes
    return () => {
      observer.disconnect()
    }
  }, [isFetching, hasMore, fetchMore])

  return observerRef
}

export default useInfiniteScroll
