import { Link, NavLink } from 'react-router-dom'
import Logo from '../assets/newLogo.png'
import trackEvent from './Tracker/MixpanelConfig'
import Avatar from 'react-avatar'
import { useUser } from '../UserContext'
import Notification from './Notification'
import useDropdown from '../hooks/useDropdown'

export default function Navbar() {
  const { user } = useUser()
  const {
    isOpen: isProfileDropdownOpen,
    toggleDropdown: toggleProfileDropdown,
    ref: dropdownRef,
  } = useDropdown()

  const {
    isOpen: showMobMenus,
    toggleDropdown: toggleHamBurgereDropdown,
    ref: burgerMenuRef,
  } = useDropdown()

  const displayName = user.displayName
  const userPicture = user.picture
  const userType = user.userType

  return (
    <div className='header'>
      <header className='header-container'>
        <Link to='/'>
          <img className='logo' src={Logo} alt='logo' />
        </Link>

        <div className='NavMenus'>
          <div
            ref={burgerMenuRef}
            className={`burgerMenu ${showMobMenus && 'active'}`}
          >
            <Link to='/game-rooms' style={{ marginRight: '15px' }}>
              Game Rooms
            </Link>
            <Link to='/global-leader-board' style={{ marginRight: '15px' }}>
              Leaderboard
            </Link>
          </div>

          {user.token && <Notification />}

          <div className='NavProfileButton' ref={dropdownRef}>
            {user.token ? (
              <div className='loggedinDropdown'>
                <div
                  className='avatarContainer'
                  onClick={toggleProfileDropdown}
                >
                  {userPicture ? (
                    <div
                      className='dpImg'
                      style={{ backgroundImage: `url(${userPicture})` }}
                    ></div>
                  ) : (
                    <Avatar
                      name={displayName}
                      size='40'
                      className='userdp'
                      textSizeRatio={2}
                    />
                  )}
                  {userType === 'admin' && (
                    <p className='navbar-admin-tag'>Admin</p>
                  )}
                </div>

                {isProfileDropdownOpen && (
                  <div className='dropdown'>
                    <li className='profileTitle'>
                      <p
                        className='oneLineText3dot'
                        style={{ marginInline: 'auto' }}
                      >
                        <b> {displayName} </b>
                      </p>
                    </li>
                    <Link to='/profile'>
                      <li className='list'>
                        <i className='bi bi-person'></i>
                        Profile{' '}
                        <i
                          className='bi bi-pencil'
                          style={{ fontSize: '0.5rem' }}
                        ></i>
                      </li>
                    </Link>
                    <Link to='/dashboard'>
                      <li className='list'>
                        <i className='bi bi-grid-1x2'></i>
                        Dashboard
                      </li>
                    </Link>
                    <Link to='/global-leader-board'>
                      <li className='list'>
                        <i className='bi bi-trophy'></i>
                        Leaderboard
                      </li>
                    </Link>
                    <Link to='/game-rooms'>
                      <li className='list'>
                        <i className='bi bi-hdd-stack'></i>
                        Game Rooms
                      </li>
                    </Link>
                    <Link to='/joined-games'>
                      <li className='list'>
                        <i className='bi bi-person-plus'></i>
                        Joined Games
                      </li>
                    </Link>
                    <Link to='/Wallet'>
                      <li className='list' style={{ display: 'flex' }}>
                        <i className='bi bi-wallet2'></i>
                        <div>Wallet</div>
                      </li>
                    </Link>
                    <Link to='/refer'>
                      <li className='list' style={{ display: 'flex' }}>
                        <i className='bi bi-person-add'></i>
                        <div>Refer</div>
                      </li>
                    </Link>
                    <NavLink to='/logout'>
                      <li className='list'>
                        <i className='bi bi-box-arrow-right'></i> Logout
                      </li>
                    </NavLink>
                  </div>
                )}
              </div>
            ) : (
              <Link to='/sign-in'>
                <button
                  className='btn shine md-none '
                  onClick={() => {
                    trackEvent('JoinFC on Navbar Clicked')
                  }}
                >
                  Join The FC!
                </button>
              </Link>
            )}
          </div>

          {/* humburgure icon only show for non logged in user */}
          {!user.token && (
            <i
              className='burgerIcon bi bi-list'
              onClick={toggleHamBurgereDropdown}
            ></i>
          )}
        </div>
      </header>
    </div>
  )
}
