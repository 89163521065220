import { Navigate } from 'react-router-dom'
import { useUser } from '../UserContext'
import PropTypes from 'prop-types'
const ProtectedRoute = ({ children, role }) => {
  const { user } = useUser()

  if (role === 'user' && !user.token) {
    return <Navigate to='/sign-in' />
  }

  if (role === 'admin' && (!user.token || user.userType !== 'admin')) {
    return <Navigate to='/sign-in' />
  }

  return children
}

export default ProtectedRoute

ProtectedRoute.propTypes = {
  children: PropTypes.element,
  role: PropTypes.string,
}
