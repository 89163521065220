import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { AdminMenus } from './Config'

export default function Sidebar() {
  const sideMenuClicked = () => {
    document.querySelector('.sidebar').classList.toggle('show')
  }

  useEffect(() => {
    var tabs = document.querySelectorAll('.sidebarBtn')
    tabs.forEach((tab) => {
      tab.classList.remove('active')
    })

    let currentPath = window.location.pathname.split('/')[1]
    // console.log("current path :", currentPath);
    AdminMenus.forEach((item) => {
      if (currentPath === item.link) {
        document.querySelector(`#${item.link}`).classList.add('active')
      }
    })
  }, [])

  return (
    <>
      <div className='sidebar' style={{ backgroundColor: 'black' }}>
        <div className='sidebarMenuBtn' onClick={sideMenuClicked}>
          <i className='bi bi-list'> </i>
        </div>
        <div>
          {/* <h5 style={{ marginTop: "10px" }}>Admin</h5> */}
          {AdminMenus.map((item) => {
            return (
              <Link key={item.link} to={`/${item.link}`}>
                <div id={item.link} className='sidebarBtn shine '>
                  <i className={item.icon}></i> {item.name}
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </>
  )
}
