import { createSlice } from '@reduxjs/toolkit'

// gameRoomSlice ========================================
export const gameRoomSlice = createSlice({
  name: 'gameRoom',
  initialState: {
    gameRoomDetails: [],
    lineupPlayers: [],
    lineupId: '',
    lineupName: '',
    showJoinPopup: false,
    showGround: true,
    showSaveLineup: false,
    showConfirmation: false,
    showAlreadySavedLineup: false,
    hasJoined: false,
    showEditLineupModel: false,
    showEditGround: true,
    showSaveEditLineup: false,
  },
  reducers: {
    setLineupId: (state, action) => {
      state.lineupId = action.payload
    },
    setLineupName: (state, action) => {
      state.lineupName = action.payload
    },
    setLineupPlayers: (state, action) => {
      state.lineupPlayers = action.payload
    },
    setShowGround: (state, action) => {
      state.showGround = action.payload
    },
    setGameRoomDetails: (state, action) => {
      state.gameRoomDetails = action.payload
    },
    setShowJoinPopup: (state, action) => {
      state.showJoinPopup = action.payload
    },
    setShowSaveLineup: (state, action) => {
      state.showSaveLineup = action.payload
    },
    setShowConfirmation: (state, action) => {
      state.showConfirmation = action.payload
    },
    setHasJoined: (state, action) => {
      state.hasJoined = action.payload
    },
    setShowAlreadySavedLineup: (state, action) => {
      state.showAlreadySavedLineup = action.payload
    },
    setShowEditLineupModel: (state, action) => {
      state.showEditLineupModel = action.payload
    },
    setShowEditGround: (state, action) => {
      state.showEditGround = action.payload
    },
    setShowSaveEditLineup: (state, action) => {
      state.showSaveEditLineup = action.payload
    },
  },
})

export const {
  setLineupId,
  setShowJoinPopup,
  setLineupName,
  setLineupPlayers,
  setShowSaveLineup,
  setShowGround,
  setGameRoomDetails,
  setShowConfirmation,
  setHasJoined,
  setShowAlreadySavedLineup,
  setShowEditLineupModel,
  setShowEditGround,
  setShowSaveEditLineup,
} = gameRoomSlice.actions

export default gameRoomSlice.reducer
