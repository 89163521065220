import { useEffect, useState } from 'react'

const UNREAD_COUNT = 'notifications.unread_count_changed'

export const useUnreadCount = ({ novuRef }) => {
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    const getUnseeCount = async () => {
      if (novuRef?.current) {
        try {
          const headlessService = novuRef.current
          const response = await headlessService.notifications.count({
            read: false,
          })
          console.log(response.data.count, 'count')
          setUnreadCount(response.data.count)
        } catch (error) {
          console.error('Failed to count unread notifications:', error)
        }
      }
    }

    getUnseeCount()

    //setup listener
    const setupUnreadCountListener = () => {
      if (!novuRef?.current) return

      const handleUnreadCountChange = (data) => {
        console.log(data.result, 'handleunreadnotification')
        setUnreadCount(data.result)
      }

      const { current: headlessService } = novuRef
      headlessService.on(UNREAD_COUNT, handleUnreadCountChange)

      return () => {
        headlessService.off(UNREAD_COUNT, handleUnreadCountChange)
      }
    }

    const cleanup = setupUnreadCountListener()
    return cleanup
  }, [novuRef])

  return [unreadCount, setUnreadCount]
}
