import PropTypes from 'prop-types'
import { useState } from 'react'
import { useNovuInit } from './hooks/useNovuInit'
import { useNotifications } from './hooks/useNotifications'
import { useUnreadCount } from './hooks/useUnreadCount'
import { NOTIF_TAB } from '../constants'
import { NotificationContext } from './NotificationContext'

const NotificationProvider = ({ children }) => {
  const [tabStatus, setTabStatus] = useState(NOTIF_TAB.UNREAD_ONLY) // all, onlyUnread
  const [hasMore, setHasMore] = useState(true)
  const [offset, setOffset] = useState(0)

  const novuRef = useNovuInit()
  const [{ notifications, isFetchingNotifications }, setNotifications] =
    useNotifications({
      novuRef,
      tabStatus,
      offset,
      setHasMore,
    })
  const [unreadCount, setUnreadCount] = useUnreadCount({ novuRef })

  const switchNotificationTab = (tab) => {
    // check to ensure tab clicked is not the same as tab already open
    if (tab !== tabStatus) {
      setNotifications([]) // clear notifications everytime tab is switched;
      setOffset(0)
      setTabStatus(tab)
    }
  }

  const fetchMoreNotifications = () => {
    console.log('fetchingMore', notifications.length)
    if (hasMore && notifications.length > 0) {
      setOffset(notifications.length)
    }
  }

  const completeNotificationPrimaryAction = async ({ id }) => {
    if (!novuRef?.current) return
    try {
      const { current: headlessService } = novuRef
      const response = await headlessService.notifications.completePrimary({
        notificationId: id,
      })

      console.log(response, 'completed primary action')
    } catch (error) {
      console.error('Failed to mark all notifications as read', error)
    }
  }

  const markAllNotificationsAsRead = async () => {
    console.log(unreadCount, 'unread count')
    if (!novuRef?.current || unreadCount === 0) return
    try {
      const { current: headlessService } = novuRef
      const response = await headlessService.notifications.readAll()
      console.log(response, 'marked as read')
      setUnreadCount(0)
      setNotifications([])
    } catch (error) {
      console.error('Failed to mark all notifications as read', error)
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        isFetchingNotifications,
        hasMore,
        tabStatus,
        unreadCount,
        markAllNotificationsAsRead,
        completeNotificationPrimaryAction,
        switchNotificationTab,
        fetchMoreNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider

NotificationProvider.propTypes = {
  children: PropTypes.element,
}
