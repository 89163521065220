import PropTypes from 'prop-types'

export default function ErrorPopup({ isError, errorMsg }) {
  return (
    <>
      {isError ? (
        <div className='errDialog'>
          <p
            className='bi bi-exclamation-triangle-fill'
            style={{ marginRight: '20px', fontSize: '25px' }}
          ></p>
          <p>{errorMsg}</p>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

ErrorPopup.propTypes = {
  isError: PropTypes.bool,
  errorMsg: PropTypes.string,
}
