import PulseLoading from '../../Component/PulseLoading'
import PropTypes from 'prop-types'
import { useApiPut } from '../../api/apiHooks'
import { getPlayerByIdUrl } from '../../api/urls'
import { useQueryClient } from '@tanstack/react-query'

function EditPointTable(props) {
  const mutation = useApiPut()
  const queryClient = useQueryClient()

  const handleCostChange = async (playerID, playerCost) => {
    let item = {
      cost: parseFloat(playerCost),
    }

    const requestBody = {
      url: getPlayerByIdUrl(playerID),
      data: item,
    }

    await mutation.mutateAsync(requestBody, {
      onSuccess: () => {
        alert('success')
        queryClient.invalidateQueries({
          queryKey: [props.getPlayerUrlWithParams],
        })
      },
      onError: (error) => {
        let msg =
          error?.response?.data?.error || error?.message || 'An error occured'
        alert(`error: ${msg}`)
      },
    })
  }

  const handlePopup = (id, display_name) => {
    const costValue = window.prompt(`Enter a cost for ${display_name}`, '')
    // Check if the user entered a value
    if (costValue !== null) {
      // If the user entered a value, do something with it
      handleCostChange(id, costValue)
    } else {
      // If the user canceled the input, show a message
      alert('You canceled the input.')
    }
  }

  return (
    <>
      <h3>players {mutation.isPending && <PulseLoading />} </h3>
      <div className='AdminLeagueTable'>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>display_name</th>
              <th>age </th>
              <th>cost</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.players &&
              props?.players?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.display_name}</td>
                    <td>{item.age}</td>
                    <td>{item.cost}</td>
                    <td>
                      <button
                        className='btn'
                        onClick={() => handlePopup(item.id, item.display_name)}
                      >
                        change
                      </button>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    </>
  )
}

EditPointTable.propTypes = {
  players: PropTypes.array.isRequired,
  getPlayerUrlWithParams: PropTypes.string.isRequired,
}

export default EditPointTable
