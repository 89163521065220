import { useState } from 'react'
import '../AdminCss.css'
import AdminNavbar from '../Components/AdminNavbar'
import AdminSidebar from '../Components/AdminSidebar'
import FormSection from './FormSection'
import EditPointTable from './EditPointTable'
import { useApiGet } from '../../api/apiHooks'
import { getPlayersUrl } from '../../api/urls'
import { pageSizes } from '../../constants'

function PlayerTransferValue() {
  const [playerQuery, setPlayerQuery] = useState({
    league: null,
    club: null,
  })
  const { league, club } = playerQuery

  const getPlayerUrlWithParams = getPlayersUrl({
    sortBy: 'name',
    sortOrder: 'ASC',
    take: pageSizes.big,
    league: league,
    clubs: `${club}&clubs=${club}`, // set twice intentionally as required by the API
  })

  const { isLoading: isFetchingPlayers, data: players } = useApiGet({
    queryKey: [getPlayerUrlWithParams],
    enabled: !!league && !!club,
  })

  return (
    <div className='LoggedInContainer'>
      <AdminSidebar />
      <AdminNavbar />

      <div className='Admin-page'>
        <h2 className='Page-Title'>Player Transfer Value</h2>

        <div className='container'>
          <FormSection
            setPlayerQuery={setPlayerQuery}
            isLoading={isFetchingPlayers}
          />
          {players?.length > 0 && (
            <EditPointTable
              players={players}
              getPlayerUrlWithParams={getPlayerUrlWithParams}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PlayerTransferValue
