// import { lazy } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'
import ScrollToTop from './Component/ScrollToTop.jsx'

import { googleConstants, NET } from './constants.js'
import ContextProvider from './Context.jsx'
import UserProvider from './UserContext.jsx'
import { WithWalletConnector } from '@concordium/react-components'
import { WalletConnectorProvider } from './WalletConnectorContext.jsx'
import AppRoutes from './AppRoutes.jsx'
import { PageTracking } from './Component/Tracker/PageTracking.js'
import ErrorUI from './Component/ErrorUI.jsx'
import NotificationProvider from './notifications/NotificationProvider.jsx'

// testing cloudflare page 1
export default function App() {
  // google analytics ======
  ReactGA.initialize(googleConstants.measuringId)

  return (
    <BrowserRouter basename='/'>
      <PageTracking />
      <ScrollToTop />
      <GoogleOAuthProvider clientId={googleConstants.measuringId}>
        <ContextProvider>
          <UserProvider>
            <NotificationProvider>
              <WithWalletConnector network={NET}>
                {(props) => (
                  <WalletConnectorProvider value={props}>
                    <Sentry.ErrorBoundary fallback={<ErrorUI />}>
                      <AppRoutes />
                    </Sentry.ErrorBoundary>
                  </WalletConnectorProvider>
                )}
              </WithWalletConnector>
            </NotificationProvider>
          </UserProvider>
        </ContextProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  )
}
