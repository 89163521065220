import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import { useEffect } from 'react'
import { mixPanelPageTrack } from './MixpanelConfig'

export function PageTracking() {
  const location = useLocation()
  useEffect(() => {
    const page = location.pathname
    ReactGA.send({ hitType: 'pageView', page })
    mixPanelPageTrack(page)
    // Enable the following line for debugging purposes only
    // console.log(page)
  }, [location])

  return null
}
